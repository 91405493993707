import "./App.css";
import { Routes, Route } from "react-router-dom";
import MenuPrincipal from "./pages/menuprincipal/MenuPrincipal";
import NosServices from "./pages/nosservices/NosServices";
import Creationdvp from "./pages/creationsdvp/Creationdvp";
import Contact from "./pages/contact/Contactdvpariege";
import Maintenance from "./pages/maintenance/Maintenance";
import PageNotFound from "./pages/notfound/Notfound";
import PageNosClients from "./pages/pagenosclients/PageNosClients";
import PageMentionsLegales from "./pages/pagementionslegales/PageMentionsLegales";
import Pagetemplate from "./pages/pagetemplate/pageprincipale/PagePrincipale";
import PageGalerie from "./pages/pagetemplate/pagegalerie/PageGalerie";
import PageContact from "./pages/pagetemplate/pagecontact/PageContact";
import PageService from "./pages/pagetemplate/pagemenu1/PageMenu1";
import PageBlogDvpAriege from "./pages/pageblogdvpariege/PageBlogDvpAriege";
function App() {
return (
<>
<div className="App">
<header className="App-header">
<Routes>
<Route path="*" element={<PageNotFound />} />
<Route path="/" element={<MenuPrincipal />} />
<Route exact path="/nosservices" element={<NosServices />} />
<Route exact path="/sitesurmesure" element={<Creationdvp />} />
<Route exact path="/contact" element={<Contact />} />
<Route exact path="/maintenance" element={<Maintenance />} />
<Route exact path="/nosclients" element={<PageNosClients />} />
<Route exact path="/mentionslegales" element={<PageMentionsLegales />} />
<Route exact path="/blogdvpariege" element={<PageBlogDvpAriege />} />
<Route exact path="/template" element={<Pagetemplate />} />
<Route exact path="/pagetemplategalerie" element={<PageGalerie />} />
<Route exact path="/contacttemplate" element={<PageContact
lundi="9h-12h 13h-18h30"
mardi="9h-12h 13h-18h30"
mercredi="9h-12h 13h-18h30"
jeudi="9h-12h 13h-18h30"
vendredi="9h-12h 13h-18h30"
samedi="9h-12h 13h-18h30"
dimanche="férmé"
tel="06.00.00.00.82"
mail="ciseauxdor@gmail.com"
adresse="33 rue de neneak 09348 Juejsa"
/>} />
<Route exact path="/menu1template" element={<PageService />} />
</Routes>
</header>
</div>
</>
);
}

export default App;