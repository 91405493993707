import "../footer/footer.css";
import logodvpariege from "../../images/logodvpariege2025.png";
const Footer = () => {
  return (
    <div className="filtreFooterDvpAriege">
    <footer className="allFooter">
      <div className="footerDownContainer">
        <div className="footerDvpAriegePart1">
        <img className="logoFooterDvpAriege" src={logodvpariege} alt="logoinsta" />
        </div>
        <div className="contactFooterContainer">
          <p className="contactFooter">06 31 55 06 82</p>
          <p className="contactFooter">dvpariege@gmail.com</p>
        </div>
        <ul className="listFooter">
          <a href="/sitesurmesure">
            <li className="liFooterStudioPhoto">Création de sites internet</li>
          </a>
          <a href="https://www.dvpariege.fr/nosservices">
            <li className="liFooterStudioPhoto">Services Photo</li>
          </a>
          <a href="https://www.dvpariege.fr/blogdvpariege">
            <li className="liFooterStudioPhoto">Le blog dvpariege</li>
          </a>
          <a href="https://www.dvpariege.fr/mentionslegales">
            <li className="liFooterStudioPhoto">Mentions légales</li>
          </a>
        </ul>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
   