import "../menuprincipal/menuprincipal.css";
import imgblog from "../../images/imgblog2025.png";
import imgrejoindreleblog from "../../images/imgrejoindreleblog.png";
import GrilleAccueil from "../../components/grilleaccueil/GrilleAccueil";
import videoaccueil from "../../videos/videoaccueil.mp4";
import Footer from "../../components/footer/Footer";
import MenuOuvrantDvpAriege from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";


const MenuPrincipal = () => {
  return (
    <div className="allMp">
      <div className="allPageMenuPrincipal">
          <div className="menuOuvrantDvpAriegeContainer">
            <MenuOuvrantDvpAriege />
          <GrilleAccueil />
        </div>
        <div className="h1pContainer">
          <h1 className="h1ADecouvrir">À Découvrir</h1>
          <p className="pADecouvrir">Le Nouveau design pour 2025!!!</p>
        </div>
        <div className="container1">
          <img
            src={imgblog}
            alt="voyage d'appareil.p de drone et le logo dvpariege "
            className="imgBlog"
          />
          <img
            src={imgrejoindreleblog}
            className="imgRejoindreLeBlog"
            alt="deux personnes qui se serrent la main"
          />
        </div>
        <div className="container2">
          <p className="textBlog1">
            Bienvenue sur le blog de dvpariege ! <br /> <br />
            Ce blog est un espace pour partager mes passions et mes découvertes.{" "}
            <br /> Je m'intéresse à de nombreux sujets, du développement web à
            la cuisine en passant par l'artisanat, la musique, le jeu vidéo, les
            voyages etc. <br /> Je suis toujours à la recherche de nouvelles
            choses à apprendre et à partager.
          </p>
          <a className="aButtonBlog" href="/blogdvpariege">
            <p className="buttonBlog">voir le blog </p>
          </a>
        </div>
      </div>
      <div className="videoAccueilContainer">
        <video className="videoAccueil" autoPlay loop muted>
          <source src={videoaccueil} type="video/mp4" />
        </video>
      <div className="questionsContainer">
        <h1 className="h1Questions">Prêt à discuter de votre projet ?</h1>
        <h2 className="h2Questions">Quelles sont les options disponibles pour améliorer votre site web ?</h2> 
        <h2 className="h2Questions">Quel est le coût d'un projet personnalisé pour vous ?</h2>
        <h2 className="h2Questions">Vous ne trouvez pas ce que vous cherchez et vous avez des questions ? <br/> <br/> Je suis là pour y répondre !</h2>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default MenuPrincipal;
