import "./creationdvp.css";
import InfoServices from "../../components/infoservices/InfoServices";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import Footer from "../../components/footer/Footer";
import CharteGraphique from "../../components/chartegraphique/CharteGraphique";
const Creationdvp = () => {
  return (
    <div className="allCreation">
      <div className="headerContainer">
        <MenuOuvrant />
      </div>
        <InfoServices />
        <CharteGraphique />
      <Footer />
      </div>
  );
};

export default Creationdvp;
