import "./grilleaccueil.css";
import logo2025 from "../../images/logodvpariege2025.png";
import gifrecherche from "../../images/gif/recherche.gif";
import imgServicesPhotos from "../../images/servicesphotos.png";
import imgBlog2 from "../../images/imgblog2.png";
import imgBlog3 from "../../images/imgblog3.png";
import imgContact from "../../images/imgContact.png";
const GrilleAccueil = () => {
  return (
    <div className="grilleAccueilContainer">
      <div className="part1Container">
        <div className="logoGifContainer">
          <img
            className="logoDvpAriege2025"
            src={logo2025}
            alt="logo dvpariege"
          />
          <a className="referencementContainer" href="/">
            <div className="grille">
              <img className="gifRecherche" src={gifrecherche} alt="loupe recherche" />
              <p className="pGrilleGif">Référencement</p>
            </div>
          </a>
        </div>
      </div>
      <a href="/nosservices" className="aServices">
      <div className="imgServicesPhotosContainer">
        <img
          className="imgServicesPhotos"
          src={imgServicesPhotos}
          alt="appareil p"
          />
      </div>
          </a>
      <div className="part2Container">
        <div className="texteInfoContainer">
          <a className="texteInfo" href="/">
            <p className="pTexteInfo">Bonne Année <br/> 2025</p>
          </a>
        </div>
      <div className="blogContactContainer">
        <a href="/blogdvpariege" className="aBlog">

        <div className="blogContainer">
          <img className="imgBlog2" src={imgBlog2} alt="crayon stylo blog" />
          <p className="pLeBlog">Je vous invite à suivre mon blog pour découvrir mes projets en cours et mes inspirations.</p>
          <p className="pTitreLeBlog">Le Blog</p>
          <img className="imgBlog3" src={imgBlog3} alt="bulle de dialogue" />
        </div>
        </a>
        <a className="aContact" href="/contact">
        <div className="contactContainer">
          <img className="imgContactd" src={imgContact} alt="bulle info telephone adresse mail"/>
          <p className="pTitreContact">contact</p>
          <p className="pContact">"J’ai hâte d'échanger avec vous ! Rendez-vous sur la page contact je suis à votre disposition pour vous renseigner."</p>
        </div>
        </a>
      </div>
      </div>
    <a href="/sitesurmesure" className="aSiteInternet">
    <div className="siteInternetSurMesureContainer">
      <p className="pSiteSurMesure">Votre site internet <br />sur mesure</p>
    </div>
    </a>
    </div>
  );
};
export default GrilleAccueil;
