import "./contactdvpariege.css";
import MenuOuvrant from "../../components/menuouvrantdvpariege/MenuOuvrantDvpAriege";
import logoDvpAriege from "../../images/logodvpariege2025.png";
import iconePortable from "../../images/pagecontact/iconePortableContact.gif";
import iconeInterogation from "../../images/pagecontact/iconecontact.gif";
import iconeMailPapier from "../../images/pagecontact/iconemailcontact.gif";
import iconeInstagram from "../../images/pagecontact/iconeInstaContact.png";
const Contact = () => {
  return (
    <>
      <div className="allContactDvpAriege">
        <div className="headerContainer">
          <MenuOuvrant />
        </div>
        <div className="part1et2ContactContainer">
          <div className="part1ContactContainer">
            <img src={logoDvpAriege} className="logoDvpAriegeContact" alt="logo dvpariege" />
            <h1 className="h1Contact">Vous Avez <br/> Un Projet ?</h1>
            <p className="sousTitreContact">Je suis à votre écoute</p>
            <p className="pContact">
            Je suis à votre écoute pour vous accompagner dans sa réalisation. Que vous souhaitiez créer un site vitrine ou un portail d'information, je mets mes compétences à votre service pour vous offrir une solution personnalisée et adaptée à vos besoins.
            </p>
          </div>
          <div className="part2ContactContainer">
            <div className="pDisponibleContainer">
              <p className="pDisponible">
                Disponible pour répondre à toutes vos questions et vous
                accompagner dans votre projet web
              </p>
            </div>
            <div className="texteHorairesContainer">
              Du Lundi Au Vendredi <br/> de 8h à 20h
            </div>
            <div className="iconePortableContactContainer">
              <img className="iconePortableContact" src={iconePortable} alt="telephone portable" />
            </div>
            <hr/>
            <div className="telMailContactContainer">
              <div className="telContactContainer">
                <p className="pTelPortable">06 31 55 06 82</p>
                <img className='iconeInterogation' src={iconeInterogation} alt="point interogation" />
              </div>
              <div className="mailContactContainer">
                <p className="pMailContact">dvpariege@gmail.com</p>
                <img className="iconeMailPapier" src={iconeMailPapier} alt="avion en papier" />
              </div>
              <hr/>
              <div className="reseauSociauxContainer">
                <p className="rsContact">Réseau sociaux</p>
                <img className="iconeInstagramContact" src={iconeInstagram} alt="icone instagram" />
                <p>instagram</p>
              </div>
            </div>
            <div className="messageConfianceContainer">
              <p className="pConfiance">Merci pour votre confiance</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
