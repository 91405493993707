import './chartegraphique.css';
import logoCharteGraphique from '../../images/pagesitesurmesure/logoCharteGraphique.png';
const CharteGraphique = () => {
    return(
        <div className="CharteGraphiqueContainer">
            <div className="CharteGraphiquePart1">
              <p className="pCharteGraphiquePart1">charte <br /> graphique</p>
            </div>
            <div className="CharteGraphiquePart2">
              <h1 className='h1CharteGraphique'>L'Importance Cruciale d'une Charte Graphique Cohérente</h1>
              <p className='pCharteGraphiquePart2'>Une charte graphique regroupe les règles d'utilisation de votre logo, de vos couleurs, de vos typographies, de vos images et de tous les autres éléments visuels. Elle assure une communication visuelle homogène et professionnelle sur tous vos supports, qu'ils soient numériques ou imprimés.</p>
              <img className='logoCharteGraphique' src={logoCharteGraphique} alt='palette de couleurs'/>
            </div>
        </div>
    )
}
export default CharteGraphique